import React from 'react'
import '../css/page-head.css'
// import pageHeadImage from '../images/page-head.jpg'


const PageHead = (props) => {
  return (
    <div className='page-head' style={{backgroundImage : `url(${props.image})`}}>
      <div className='page-head-overlay'></div>
      <div className='page-head-content'>
        <h1>{props.page}</h1>
        <p>{props.content}</p>
      </div>
    </div>
  )
}

export default PageHead
