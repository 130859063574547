import React from 'react'
// import pdfFile from '../components/PROLUMIN_VOL1_.pdf'

const ProductDisplay = (props) => {
  const {data} = props;

  // const handleFeatures = (e, element) => {
  //   let featureContainer = e.target.parentElement.nextElementSibling.children;
  //   let featureBtns = e.target.parentElement.children;
  //   for(let i = 0; i< featureContainer.length; i++){
  //     featureContainer[i].classList.add('d-none');
  //     featureBtns[i].classList.remove('active');
  //   }
  //   featureContainer[element].classList.remove('d-none');
  //   featureBtns[element].classList.add('active');
  // }

  return (
      <div className='single-product'>
        <div className='product-image'>
          <img src={data.image} alt='...' />
          <div className='product-gradient'></div>
          <div className='product-name'>
            <h1>{data.name}</h1>
          </div>
        </div>
        <div className='product-description'>
          <p>{data.desc}</p>
          <div className='features-btns'>
          </div>
        </div>
      </div>
  )
}

export default ProductDisplay
